import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/AutoDocLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Name`}</h2>
    <p><strong parentName="p">{`step certificate needs-renewal`}</strong>{` -- Check if a certificate needs to be renewed`}</p>
    <h2>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-raw"
      }}>{`step certificate needs-renewal <cert-file or hostname>
[--expires-in=<percent|duration>] [--bundle] [--verbose]
[--roots=<root-bundle>] [--servername=<servername>]
`}</code></pre>
    <h2>{`Description`}</h2>
    <p><strong parentName="p">{`step certificate needs-renewal`}</strong>{` returns '0' if the certificate needs
to be renewed based on its remaining lifetime. Returns '1' the certificate is
within its validity lifetime bounds and does not need to be renewed.
By default, a certificate "needs renewal" when it has passed 66% (default
threshold) of its allotted lifetime. This threshold can be adjusted using the
'--expires-in' flag. Additionally, by default only the leaf certificate will
be checked by the command; to check each certificate in the chain use the
'--bundle' flag.`}</p>
    <h2>{`Positional arguments`}</h2>
    <p><inlineCode parentName="p">{`cert-file or hostname`}</inlineCode>{`
The path to a certificate OR a hostname with protocol prefix.`}</p>
    <h2>{`Options`}</h2>
    <p><strong parentName="p">{`--expires-in`}</strong>{`=`}<inlineCode parentName="p">{`percent|duration`}</inlineCode>{`
Check if the certificate expires within the given time window
using `}<inlineCode parentName="p">{`percent|duration`}</inlineCode>{`. If using `}<inlineCode parentName="p">{`percent`}</inlineCode>{`, the input must be followed by a "%"
character. If using `}<inlineCode parentName="p">{`duration`}</inlineCode>{`, the input must be a sequence of decimal numbers,
each with optional fraction and a unit suffix, such as "300ms", "-1.5h" or "2h45m".
Valid time units are "ns", "us" (or "µs"), "ms", "s", "m", "h".`}</p>
    <p><strong parentName="p">{`--roots`}</strong>{`=`}<inlineCode parentName="p">{`roots`}</inlineCode>{`
Root certificate(s) that will be used to verify the
authenticity of the remote server.`}</p>
    <p><inlineCode parentName="p">{`roots`}</inlineCode>{` is a case-sensitive string and may be one of:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`file`}</strong>{`: Relative or full path to a file. All certificates in the file will be used for path validation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`list of files`}</strong>{`: Comma-separated list of relative or full file paths. Every PEM encoded certificate from each file will be used for path validation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`directory`}</strong>{`: Relative or full path to a directory. Every PEM encoded certificate from each file in the directory will be used for path validation.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`--bundle`}</strong>{`
Check all certificates in the order in which they appear in the bundle.`}</p>
    <p><strong parentName="p">{`--verbose`}</strong>{`, `}<strong parentName="p">{`-v`}</strong>{`
Print human readable affirmation if certificate requires renewal.`}</p>
    <p><strong parentName="p">{`--servername`}</strong>{`=`}<inlineCode parentName="p">{`value`}</inlineCode>{`
TLS Server Name Indication that should be sent to request a specific certificate from the server.`}</p>
    <h2>{`Exit codes`}</h2>
    <p>{`This command returns '0' if the X509 certificate needs renewal, '1' if the
X509 certificate does not need renewal, '2' if the X509 certificate file does not
exist, and '255' for any other error.`}</p>
    <h2>{`Examples`}</h2>
    <p>{`Check if the leaf certificate in the file certificate.crt has passed 66 percent of its validity period:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate needs-renewal ./certificate.crt
`}</code></pre>
    <p>{`Check if any certificate in the bundle has passed 66 percent of its validity period:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate needs-renewal ./certificate.crt --bundle
`}</code></pre>
    <p>{`Check if the leaf certificate provided by smallstep.com has passed 66 percent
of its vlaidity period:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate needs-renewal https://smallstep.com
`}</code></pre>
    <p>{`Check if any certificate in the bundle for smallstep.com has has passed 66 percent
of its validity period:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate needs-renewal https://smallstep.com --bundle
`}</code></pre>
    <p>{`Check if certificate.crt expires within 1 hour 15 minutes from now:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate needs-renewal ./certificate.crt --expires-in 1h15m
`}</code></pre>
    <p>{`Check if certificate for smallstep.com is expired or not:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate needs-renewal https://smallstep.com --expires-in 0s
`}</code></pre>
    <p>{`Check if certificate has passed 75 percent of its validity period:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate needs-renewal ./certificate.crt --expires-in 75%
`}</code></pre>
    <p>{`Check a remote certificate using a custom root certificate:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate needs-renewal https://smallstep.com --roots ./root-ca.crt
`}</code></pre>
    <p>{`Check a remote certificate using a custom list of root certificates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate needs-renewal https://smallstep.com \\
--roots "./root-ca.crt,./root-ca2.crt,/root-ca3.crt"
`}</code></pre>
    <p>{`Check a remote certificate using a custom directory of root certificates:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`$ step certificate needs-renewal https://smallstep.com \\
--roots "./path/to/root/certificates/"
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      